import React from 'react'
import AdminDocuments from "./AdminDocuments"


function adminDocumentsDisplay() {
  return (
    <>
    <AdminDocuments/>
    </>
  )
}

export default adminDocumentsDisplay