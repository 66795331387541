import React from 'react'
import AddAdmin from './AddAdmin'



function AddAdminDisplay() {
  return (
    <>
    <AddAdmin/>
    </>
  )
}

export default AddAdminDisplay