import React from 'react'
import ErrorPage from './ErrorPage'

function ErrorPageDisplay() {
  return (
    <>
        <ErrorPage/>
    </>
  )
}

export default ErrorPageDisplay