import React from "react";
import Login from "./login";
import Navbar from "../../components/landingPageNavbar/Navbar";
import Footer from "../Footer/Footer";

function LoginDisplay() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginDisplay;
