import React from 'react'
import AdminElder from './AdminElder'


function AdminElderDisplay() {
  return (
    <>
    <AdminElder/>
    </>
  )
}

export default AdminElderDisplay