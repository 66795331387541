// Logs.js

import React, { useEffect, useState } from 'react';

import ActionLogs from './ActionLogs'

function ActionDisplay() {
 
  return (
    <div>

     <ActionLogs/>
    </div>
  );
}

export default ActionDisplay;
