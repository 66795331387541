import React from 'react';
import Blog from './Blog'


function BlogApp() {
    return (
        <>
            <Blog />

        </>
    )
}

export default BlogApp;