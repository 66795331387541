import React from 'react';
import AdminDashboard from './Dashboard';


function DashboardDisplay() {
    return (
        <>
            <AdminDashboard />
        </>
    )
}

export default DashboardDisplay;

