import React from 'react'

import ElderPortal from './ElderPortal'


function ElderPortalDisplay() {
  return (
    <div>
       
         <ElderPortal/>
    </div>
  )
}

export default ElderPortalDisplay