import React from 'react'
import NewsPublish from './NewsPublish'


function NewsDisplay() {
  return (
    <>
    <NewsPublish/>
    </>
  )
}

export default NewsDisplay