import IDverification from "../adminIDverification/IDverification";
import AdminNavbar from "../../components/admin-navbar/AdminNavbar";
import AdminSidebar from "../../components/admin_sidebar/AdminSidebar";
import React from 'react';

function IDverificationDisplay () {
    return (
        <>
        <IDverification />
        </>
    )
}

export default IDverificationDisplay;
